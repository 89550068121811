<template>
  <div class="row">
    <div class="col-12">
      <div class="home">Inicio</div>
    </div>

    <div class="col-6 mt-5">
      <router-link
        :to="{ name: 'token-get' }"
        class="btn btn-primary w-100 shadow mb-4"
      >
        <span>
          <font-awesome-icon icon="key" class="menu-icon w-100 fa-2x my-3" />
        </span>
        Token Dinámico</router-link
      >
    </div>
    <div class="col-6 mt-5">
      <router-link
        :to="{ name: 'configuration' }"
        class="btn btn-dark w-100 shadow"
      >
        <span>
          <font-awesome-icon icon="gear" class="menu-icon w-100 fa-2x my-3" />
        </span>
        Configuración</router-link
      >
    </div>
    <!-- <div class="col-12 mt-4">
      <router-link
        :to="{ name: 'notifications' }"
        class="btn btn-primary w-100 disabled"
        >Notificaciones</router-link
      >
    </div> -->
    <div class="col-12 mt-5 pt-5"></div>
  </div>
  <!-- <qrcode-stream @decode="onDecode" @init="onInit" /> -->
</template>

<script>
import { ref, onMounted } from "vue";
// import {
//   QrcodeStream,
//   QrcodeDropZone,
//   QrcodeCapture,
// } from "vue3-qrcode-reader";

import API from "@/api";

export default {
  name: "Home",
  components: {
    // QrcodeStream,
  },
  setup() {
    const checkSuscription = localStorage.getItem("subscription");
    const NotificationsEnabled = ref(false);
    const checkNotification = ref("");

    // const requestPermission = async () => {
    //   const permission = await Notification.requestPermission();

    //   if (permission !== "granted") {
    //     return false;
    //   }

    //   return true;
    // };

    const subscription = async () => {
      const register = await navigator.serviceWorker.register("/worker.js", {
        scope: "/",
      });

      const permission = await requestNotificationPermission();
      if (permission === "granted" && !localStorage.getItem("subscription")) {
        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            "BFnMvSL2RYP1xgj-Jod7H-QlGz9VUcxDjed5io-7evZsEQkDqozmZeB2ttd3WUCWhmwuYkTWa1Kt6d2dUIRoQd0"
          ),
        });

        await API.post("/v1/session/subscribe", subscription);

        localStorage.setItem("subscription", true);
      }
    };

    const requestNotificationPermission = () => {
      if (NotificationsEnabled && Notification.permission === "granted") {
        console.log("Permiso de notificación previamente concedido.");
        return Promise.resolve("granted");
      }

      if (NotificationsEnabled && Notification.permission !== "denied") {
        return Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Permiso de notificación concedido.");
          } else {
            console.log("No se puede obtener permiso para notificar.");
          }
          return permission;
        });
      }

      return Promise.resolve("denied");
    };

    const urlBase64ToUint8Array = (base64String) => {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    };

    onMounted(async () => {
      if ("Notification" in window) {
        NotificationsEnabled.value = true;
        checkNotification.value = Notification.permission;
      }

      if ("serviceWorker" in navigator) {
        subscription().catch((err) => console.log(err));
      }
    });

    return {
      checkSuscription,
      // checkNotification,
    };
  },
};
</script>
